.home,
.alien{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.home__select-marketing {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 15px 0;
  gap: 10px;
}

/* .home__select-marketing li {
  background: #eaeaea;
  padding: 5px 20px;
  border-radius: 10px;
} */

.home__select-marketing a {
  text-decoration: none;
  font-weight: 600;
  color: #e93333;

  background: #eaeaea;
  padding: 5px 20px;
  border-radius: 10px;
}

.home__select-marketing a:hover {
  background: #e93333;
  color: #fff;
  cursor: pointer;
}

.alien h1 {
  font-size: 6em;
  margin: 0;
}

.clientform{
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 100px 25vw;
  font-size: 17px;
}

.head__logo-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  margin-bottom: 30px;
}

.clientform--head img {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.clientform--head h1{
  font-size: 55px;
  margin: 0;
}

.clientform--head h1 span,
.head__logo-title h1 span,
.alien h1 span,
legend span {
  color: #e93333;
}

.clientform--head p span {
  color: #e93333;
  font-weight: 700;
}

.clientform hr {
  border: 1px solid #3d424f;
  margin: 40px 0;
}

legend {
  padding: 0 10px;
  font-weight: 700;
  letter-spacing: 1px;
}

fieldset {
  border-radius: 10px;
  /* padding: 10px 27px 15px 12px; */
  margin: 25px 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 15px;
}

.vw50 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.vw50 fieldset {
  width: 50%;
  margin: 0 0 20px 0;
}

fieldset h3 {
  margin-left: 10px;
  font-size: 1.3em;
  border-left: 10px solid #e93333;
  padding-left: 10px;
}

select{
  min-width: 30vw;
  min-height: 2em;
  padding-left: 10px;
  font-size: 17px;
  letter-spacing: 0.5px;
  background: #2789df;
  color: #fff;
  font-weight: 600;
  border: 0;
}

textarea {
  width: 95%;
  min-height: 10vw;
  padding: 10px;
  font-size: 17px;
  letter-spacing: 0.5px;
  resize: none;
}

fieldset input[type=text],
fieldset input[type=date],
fieldset input[type=time],
fieldset input[type=number] {
  width: 100%;
  min-height: 2em;
  padding: 0;
  font-size: 17px;
  letter-spacing: 0.5px;
  padding-block: 0px;
  padding-inline: 0;
  text-indent: 10px;
  border: 0;
}

fieldset input[type=date],
fieldset input[type=time] {
  text-indent: 3px;
}

fieldset input[type=text]:focus-visible,
fieldset input[type=number]:focus-visible,
fieldset textarea[type=text]:focus-visible,
fieldset input[type=date]:focus-visible,
fieldset input[type=time]:focus-visible {
  outline: #ff0000 auto 2px;
}

fieldset input.sisa-pemb:focus-visible {
  outline: none;
}

fieldset.konfirmasi {
  padding: 15px 10px;
}

.konfirmasi div{
  display: inline-grid;
  gap: 10px;
  margin: 10px;
}

.sisa-pemb {
  background: #2789df;
  color: #fff;
  font-weight: 700;
}

button {
  background: #e93333;
  border: 0;
  font-size: 15px;
  font-family: "Montserrat", -apple-system, sans-serif;
  font-weight: 700;
  color: #fff;
  padding: 10px 50px;
  border-radius: 10px;
  cursor: pointer;
  margin: 20px 0;
}

button:hover {
  background: #e27878;
}

@media only screen and (max-width: 1079px) {
  .clientform {
    padding: 100px 15vw;
  }
}


@media only screen and (max-width: 600px) {
  .clientform {
    padding: 50px 20px;
  }

  .clientform--head h1 {
    font-size: 40px;
  }

  .clientform--form-title{
    font-size: 1.6em;
  }

  .head__logo-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  fieldset {
    margin: 20px 0;
  }

  .subset fieldset{
    margin: 8px 0;
  }

  fieldset h3 {
    font-size: 1.2em;
  }

  .konfirmasi div {
    display: block;
    margin: 10px 5px 0 0;
  }

  select {
    min-width: inherit;
    width: 100%;
  }

  .vw50 {
    gap: 5px;
    display: block;
  }

  .vw50 fieldset {
    width: inherit;
  }

  textarea {
    min-height: 35vw;
  }
}